var YT = YT || {};

(function ($, prodcat, site) {
  'use strict';

  Drupal.behaviors.productImageFullV1 = {
    unisonDependent: true,
    unisonReady: false,
    isCr21:
      typeof Drupal.settings.globals_variables?.cr21_spp !== 'undefined'
        ? Drupal.settings.globals_variables?.cr21_spp
        : true,
    topPos: 0,
    selector: {
      body: 'body',
      productFull: '.js-product-full',
      container: '.js-product-full__images',
      carousel: '.js-spp-carousel',
      carouselSlide: '.js-spp-carousel__slide',
      carouselThumbsWrapper: '.js-product-thumbs',
      carouselThumbs: '.js-product-thumb',
      carouselDots: '.js-carousel-dots',
      thumbsContainer: '.js-product-full__carousel-thumbs-container',
      upArrow: '.js-product-full__carousel-thumbs-arrow--up',
      downArrow: '.js-product-full__carousel-thumbs-arrow--down',
      altPosterWrapper: '.js-spp-alt-video-poster-wrapper',
      altPoster: '.js-spp-alt-video-poster',
      player: '.js-alt-video-wrapper',
      playIcon: '.js-video-play-icon',
      altVideoClose: '.js-alt-video-close',
      zoomModal: '.js-img-zoom-modal'
    },

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }

      // If Unison is not ready on attach, it triggers change on initialization
      if (Unison.fetch.now()) {
        self.unisonReady = true;
      }

      Unison.on('change', function () {
        if (!self.attached && self.unisonDependent) {
          // Re attach on unisonReady, the initial attach was return
          self.attach(context);
        }
      });

      if (!self.unisonReady && self.unisonDependent) {
        return;
      }

      // If there's no full images, get outta here.
      var $modules = $('.js-product-full__image', context);

      if ($modules.length < 1) {
        return;
      }

      // Initialize all carousels.
      $modules.each(function () {
        var $this = $(this);

        self.getDom($this, context);
        self.setEvents($this);
        $this.data('initialLoad', true);
        self.changeSku($this.closest('.js-product-full').data('skuBaseId'), $this);
      });

      self.attached = true;
    },
    getDom: function ($that, context) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$body = $(self.selector.body, context);
      $that.nodes.$productFull = $that.closest(self.selector.productFull);
      $that.nodes.$container = $that.closest(self.selector.container);
      $that.nodes.$carousel = $(self.selector.carousel, $that);
      $that.nodes.$carouselSlide = $(self.selector.carouselSlide, $that);
      $that.nodes.$carouselThumbsWrapper = $(self.selector.carouselThumbsWrapper, $that);
      $that.nodes.$carouselThumbs = $(self.selector.carouselThumbs, $that);
      $that.nodes.$carouselDots = $(self.selector.carouselDots, $that);
      $that.nodes.$altPosterWrapper = $(self.selector.altPosterWrapper, $that.nodes.$carousel);
      $that.nodes.$altPoster = $(self.selector.altPoster, $that.nodes.$altPosterWrapper);
      $that.nodes.$thumbsContainer = $(self.selector.thumbsContainer, $that);
      $that.nodes.$upArrow = $(self.selector.upArrow, $that);
      $that.nodes.$downArrow = $(self.selector.downArrow, $that);
      $that.nodes.$player = $(self.selector.player, $that);
      $that.nodes.$playIcon = $(self.selector.playIcon, $that);
      $that.nodes.$altVideoClose = $(self.selector.altVideoClose, $that);
      $that.nodes.$zoomModal = $(self.selector.zoomModal, $that.nodes.$body);
      $that.nodes.$zoomCarousel = $that.nodes.$zoomModal
        ? $(self.selector.carousel, $that.nodes.$zoomModal)
        : null;
      $that.nodes.$zoomThumbsWrapper = $that.nodes.$zoomModal
        ? $(self.selector.carouselThumbsWrapper, $that.nodes.$zoomModal)
        : null;
      $that.nodes.$zoomThumbs = $that.nodes.$zoomModal
        ? $(self.selector.carouselThumbs, $that.nodes.$zoomModal)
        : null;
    },

    setEvents: function ($that) {
      var self = this;

      Unison.on('change', function () {
        self.isCr21 && !self.isMobile()
          ? self.zoomFullPage($that.nodes.$carousel)
          : self.setZoom($that.nodes.$carousel);
        self.isMobile() && $that.nodes.$zoomModal?.length > 0
          ? $that.nodes.$zoomModal?.addClass('hidden')
          : null;
        self.bindResponsiveCarousel($that);

        // Resize thumbs carousel based on images carousel
        if (self.isCr21) {
          $that.nodes.$zoomThumbsWrapper?.css('height', $that.nodes.$zoomCarousel.width());
        }
        self.setActiveThumb($that, $that.nodes.$carousel);
      });

      $(document).on(
        'click',
        '.js-kit-product-shade, .js-shade-select-option',
        function (event, skuBaseId) {
          var $selected = $(this);
          var $container = $selected.closest('.js-product-full');

          if ($selected.length < 1 || $container.length < 1) {
            return;
          }

          self.changeSku(skuBaseId, $that);
        }
      );

      // On sku change, update the primary image carousel
      $(document).on('product.skuSelect', '.js-product-ui', function (event, skuBaseId) {
        var $selected = $(this);
        var $container = $selected.closest('.js-product-full');

        if ($selected.length < 1 || $container.length < 1) {
          return;
        }

        self.changeSku(skuBaseId, $that);
      });
    },

    initProductCarousel: function ($that, context) {
      var self = this;
      var isCustomKitRedesign = $that.nodes.$productFull.hasClass('js-custom-kit-product-redesign');
      var posterindex = $that.nodes.$carouselSlide.index($that.nodes.$altPosterWrapper);

      self.isCr21 =
        $that.nodes.$productFull.length > 0 && isCustomKitRedesign
          ? !isCustomKitRedesign
          : self.isCr21;
      if (self.isCr21 && !self.isMobile()) {
        self.zoomFullPage($that.nodes.$carousel);
        self.getDom($that, context);
        self.processThumbs($that.nodes.$zoomModal, $that.nodes.$zoomCarousel, posterindex);
        self.bindResponsiveCarousel($that);
        self.setActiveThumb($that.nodes.$zoomModal, $that.nodes.$zoomCarousel);
      } else {
        self.setZoom($that.nodes.$carousel);
        self.processThumbs($that, $that.nodes.$carousel, posterindex);
        self.bindResponsiveCarousel($that);
        self.setActiveThumb($that, $that.nodes.$carousel);
      }

      if ($that.nodes.$altPoster.length > 0) {
        self.initAltVideo($that);
      }
    },
    initAltVideo: function ($that) {
      var self = this;
      // Reinitialize vars based on current context
      var $altVideoPosterWrapper = $(self.selector.altPosterWrapper, $that).not('.slick-cloned');
      var $altPoster = $(self.selector.altPoster, $altVideoPosterWrapper);
      var $carouselSlide = $(self.selector.carouselSlide, $that);
      var altVideoPosition = parseInt(
        $altVideoPosterWrapper.children($altPoster).data('videoPosition'),
        10
      );
      var isSlickInitialized = $altVideoPosterWrapper.closest('.slick-initialized').length > 0;
      var slideLength = isSlickInitialized
        ? $carouselSlide.not('.slick-cloned').length
        : $carouselSlide.length;

      altVideoPosition = altVideoPosition > 8 ? 8 : altVideoPosition;

      var insertPosition = slideLength < altVideoPosition ? slideLength - 1 : altVideoPosition - 1;

      if (insertPosition !== $altVideoPosterWrapper.index()) {
        $altVideoPosterWrapper.insertBefore($carouselSlide.eq(insertPosition));
      }

      self.setup($that);
    },
    createVideoPlayerDiv: function ($elem, i) {
      var id = $elem.attr('class') + '-' + i;

      $elem.append('<div />').children('div').attr('id', id);

      return id;
    },
    addVideo: function (ytId, iframeId, ytHeight) {
      /* global YT */
      // Empty value to stop parameters being eaten by incorrect video IDs with parameters appended
      var player = new YT.Player(iframeId, {
        height: ytHeight,
        width: '100%',
        videoId: ytId,
        playerVars: {
          empty: '',
          controls: 1,
          showinfo: 0,
          rel: 0
        },
        events: {
          onReady: function (event) {
            event.target.playVideo();
          },
          onStateChange: function () {
            // Stuff
          }
        }
      });
    },
    setup: function ($that) {
      var self = this;
      var $altVideoPosterWrapper = $(self.selector.altPosterWrapper, $that);
      var $altVideoPoster = $(self.selector.altPoster, $that);
      var $altVideoClose = $(self.selector.altVideoClose, $that);

      $altVideoPosterWrapper.each(function () {
        var $wrap = $(this);
        // Reinitialize vars based on current context
        var $player = $(self.selector.player, $that);
        var $playIcon = $(self.selector.playIcon, $that);

        $wrap.keyup(function (e) {
          var keyCode = site.getKeycode(e);

          if (keyCode === 13) {
            $wrap.trigger('click');
          }
        });

        $wrap.once().on('click', function (e) {
          e.preventDefault();

          var $this = $(this);
          var $thisPlayer = $this.children(self.selector.player);
          var $thisPlayIcon = $this.children(self.selector.playIcon);
          var $thisAltVideoPoster = $this.children(self.selector.altPoster);
          var $thisAltVideoClose = $this.children(self.selector.altVideoClose);
          var ytId = $thisAltVideoPoster.data('videoSource');
          var ytHeight = $thisAltVideoPoster.height();

          if ($thisPlayer.hasClass('video-active')) {
            return;
          }

          $player.not($thisPlayer).removeClass('video-active').empty();
          $playIcon.add($altVideoPoster).removeClass('hidden').empty();
          $altVideoClose.addClass('hidden');
          $that.removeClass('alt-video-playing');

          // Create a new div for the player to take over
          var iframeId = self.createVideoPlayerDiv(
            $thisPlayer,
            Math.floor(Number(new Date()) / 1000)
          );

          $thisPlayer.addClass('video-active');
          $that.addClass('alt-video-playing');
          $thisPlayIcon.add($thisAltVideoPoster).addClass('hidden');
          $thisAltVideoClose.removeClass('hidden');

          self.addVideo(ytId, iframeId, ytHeight);

          return false;
        });

        $altVideoClose.once().on('click', function (e) {
          e.stopPropagation();
          $(this).addClass('hidden');
          $player.removeClass('video-active').empty();
          $playIcon.add($altVideoPoster).removeClass('hidden').empty();
          $that.removeClass('alt-video-playing');
        });
      });
    },
    changeSku: function (skuBaseId, $that) {
      var self = this;
      if (!skuBaseId) {
        return;
      }
      var skuData = prodcat.data.getSku(skuBaseId);
      var prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
      var $carousel = $that.find(self.selector.carousel);
      var currentSku;

      if ($that.length < 1) {
        return;
      }
      // Check against current sku | avoid rendering twice
      currentSku = parseInt($that.attr('data-sku-base-id'));
      if (skuBaseId === currentSku) {
        if ($that.data('initialLoad')) {
          self.initProductCarousel($that);
          $that.data('initialLoad', false);
        }
        return;
      }

      $that.attr('data-sku-base-id', skuBaseId);
      // Parse new template for changing regions.
      var product_image_full = site.template.get({
        name: 'product_image_full',
        data: {
          ...prodData,
          defaultSku: skuData
        }
      });
      var newSlides = $(product_image_full).find(self.selector.carousel).html();
      var newThumbs = $(product_image_full).find(self.selector.carouselThumbsWrapper).html();

      // Prevent carousel from jump around while animation takes place on sku change.
      if ($carousel.height() > 0) {
        $carousel.css({ height: $carousel.height() + 'px' });
      }
      // Granular template updates.
      $carousel.fadeTo(100, 0, 'linear', function () {
        // Write the new sku index.
        $carousel.removeClass('slick-initialized');
        // Replace markup.
        $carousel.html(newSlides);
        $that.find(self.selector.carouselThumbsWrapper).html(newThumbs);
        self.initProductCarousel($that);
        $carousel.fadeTo(100, 1, 'linear');
        $carousel.css({ height: '' });
      });
    },

    getPosition: function (direction, $that) {
      var self = this;
      var position = {};
      var $sliderThumbs = $(self.selector.carouselThumbs, $that);
      var thumbHeight = $sliderThumbs.filter(':visible').eq(0).height();
      var thumbsMax = self.isCr21 ? 7 : 5;

      position.maxHeight = thumbHeight * ($sliderThumbs.length - thumbsMax);
      if (direction === 'up') {
        self.topPos = parseInt(self.topPos, 10) - thumbHeight;
      } else if (direction === 'down') {
        self.topPos = parseInt(self.topPos, 10) + thumbHeight;
      }
      position.topPos = self.topPos;

      return position;
    },
    setActiveThumb: function ($that, $carousel, index) {
      var self = this;
      // Reinitializing vars based on current context
      var $sliderThumbs = $(self.selector.carouselThumbs, $that);
      var $thumbsContainer = $(self.selector.carouselThumbsWrapper, $that);
      var $activeThumb = $sliderThumbs.eq(index).length
        ? $sliderThumbs.eq(index)
        : $sliderThumbs.last();
      var isVisible = self.isVisibleThumb($thumbsContainer[0], $activeThumb[0]);

      $sliderThumbs.filter('.active').removeClass('active');
      $activeThumb.addClass('active');

      if (!self.isCr21 && !isVisible) {
        if (index > $carousel.attr('data-current-slide')) {
          self.slideCarousel('down', $that);
        } else if (index < $carousel.attr('data-current-slide')) {
          self.slideCarousel('up', $that);
        }
      }
      $carousel.attr('data-current-slide', index);
    },
    processThumbs: function ($that, $carousel, posterIndex) {
      var self = this;
      var $posterThumb;
      // Reinitializing vars based on current context
      var $sliderThumbs = $(self.selector.carouselThumbs, $that);
      var $upArrow = $(self.selector.upArrow, $that);
      var $downArrow = $(self.selector.downArrow, $that);
      var thumbsMax = self.isCr21 ? 7 : 5;

      // Move video alt poster thumb to the correct position
      if (posterIndex >= 0) {
        $posterThumb = $sliderThumbs.find(self.selector.altPoster);
        $posterThumb.parent().insertAfter($sliderThumbs[posterIndex - 1]);
        $sliderThumbs = $(self.selector.carouselThumbs, $that);
      }
      if (self.isCr21) {
        $upArrow.add($downArrow).css('display', 'inline-block').removeClass('invisible');
      }
      // Carouselize the slider thumb up
      if (self.isCr21 || $sliderThumbs.length > thumbsMax) {
        $upArrow.css('display', 'inline-block');
        $upArrow.once().on('click', function () {
          $sliderThumbs = $(self.selector.carouselThumbs, $that);
          self.slideCarousel('up', $that);
          if (self.isCr21 && !$sliderThumbs.filter('.active').is($sliderThumbs.first())) {
            self.setActiveThumb($that, $carousel, $sliderThumbs.filter('.active').index() - 1);
            $carousel.slickGoTo($carousel.slickCurrentSlide() - 1);
          }
        });
      }

      // Carouselize the slider thumb down
      if (self.isCr21 || $sliderThumbs.length > thumbsMax) {
        $downArrow.css('display', 'inline-block').removeClass('invisible');
        $downArrow.once().on('click', function () {
          $sliderThumbs = $(self.selector.carouselThumbs, $that);
          self.slideCarousel('down', $that);
          if (self.isCr21 && !$sliderThumbs.filter('.active').is($sliderThumbs.last())) {
            self.setActiveThumb($that, $carousel, $sliderThumbs.filter('.active').index() + 1);
            $carousel.slickGoTo($carousel.slickCurrentSlide() + 1);
          }
        });
      }

      // Bind switch slide.
      $sliderThumbs.once().on('click', function (e) {
        e.preventDefault();

        var $sliderThumb = $(this);
        var thumbIndex = $sliderThumb.index();

        // If already selected, ignore.
        if ($sliderThumb.hasClass('active')) {
          return;
        }
        $carousel.slickGoTo(thumbIndex);
        $carousel.find(self.selector.carouselSlide).removeClass('slick-active');
        $carousel.find(self.selector.carouselSlide).eq(thumbIndex).addClass('slick-active');
        self.setActiveThumb($that, $carousel, thumbIndex);
      });
    },
    slideCarousel: function (direction, $that) {
      var self = this;
      var position = {};

      position = self.getPosition(direction, $that);
      var $upArrow = $that.find(self.selector.upArrow);
      var $downArrow = $that.find(self.selector.downArrow);
      var $thumbsContainer = $that.find(self.selector.thumbsContainer);

      if (!self.isCr21) {
        if (position.topPos === 0) {
          $upArrow.addClass('invisible');
          $downArrow.removeClass('invisible');
        } else if (position.topPos === position.maxHeight) {
          $upArrow.removeClass('invisible');
          $downArrow.addClass('invisible');
        } else {
          $upArrow.removeClass('invisible');
          $downArrow.removeClass('invisible');
        }
      }
      $thumbsContainer.animate({ scrollTop: position.topPos + 'px' }, '500', 'swing');
    },
    /* Handles the responsive checks and actual binding/unbinding of slick.
     @todo when we update slick, use slick responsive to unbind/rebind slick
     at a certain breakpoint. */
    bindResponsiveCarousel: function ($that) {
      var self = this;
      var isRTL = site.direction.isRTL && Drupal?.settings?.forceSlickRTL;
      var arrowSettings = { arrows: true };
      var slickResponsiveSettings = self.isCr21 ? 'unslick' : arrowSettings;
      var initialSlide = $that.nodes.$carousel.attr('data-current-slide') || 0;
      var isCustomKitRedesign = $that.nodes.$productFull.hasClass('js-custom-kit-product-redesign');
      var slickSettings = {
        rtl: isRTL,
        appendDots: $that.nodes.$carouselDots,
        horizontal: true,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        lazyLoad: 'ondemand',
        onAfterChange: function (args) {
          if (!$that.nodes.$carousel.hasClass('slick-initialized')) {
            return;
          }

          var currentSlide = $that.nodes.$carousel.slickCurrentSlide();

          $that.nodes.$carousel.attr('data-current-slide', currentSlide);
          var drupalAnalytics = Drupal.settings.analytics.alt_image_tagging_enabled;

          if (drupalAnalytics) {
            $(document).trigger('SLICKCHANGE', args.$slides.get(args.currentSlide));
          }
          if ($that.nodes.$altPoster.length > 0) {
            $that.nodes.$player.removeClass('video-active').empty();
            $that.nodes.$playIcon.add($that.nodes.$altPoster).removeClass('hidden').empty();
          }
        },
        responsive: [
          {
            breakpoint: 1025,
            settings: slickResponsiveSettings
          }
        ]
      };

      if (self.isCr21) {
        slickSettings.infinite = self.isMobile();
        slickSettings.dots = self.isMobile();
      } else {
        slickSettings.dots = false;
        slickSettings.arrows = isCustomKitRedesign ? true : arrowSettings;
        slickSettings.infinite = true;
      }

      if (!self.isCr21 || self.isMobile()) {
        $that.nodes.$carousel.unslick();
        $that.nodes.$carousel.not('.slick-initialized').slick(slickSettings);
        $that.nodes.$carousel
          .slickSetOption('speed', 0)
          .slickGoTo(initialSlide)
          .slickSetOption('speed', 300);
      } else if (
        self.isCr21 &&
        $that.nodes.$zoomCarousel &&
        !$that.nodes.$zoomCarousel.hasClass('slick-initialized') &&
        !self.isMobile()
      ) {
        $that.nodes.$zoomCarousel.not('.slick-initialized').slick(slickSettings);
        $that.nodes.$zoomCarousel
          .slickSetOption('speed', 0)
          .slickGoTo(initialSlide)
          .slickSetOption('speed', 300);
      } else if (self.isCr21 && !self.isMobile()) {
        $that.nodes.$carousel.unslick();
      }
    },

    isMobile: function () {
      var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
      var bPlargeUp = parseInt(Unison.fetch.all()['usn-medium'], 10);

      return currentBpWidth <= bPlargeUp;
    },

    zoomFullPage: function ($carousel) {
      var self = this;
      var $imageModal = $();
      var $container = $carousel.closest('.js-product-full');
      var $thumbs = $carousel.siblings(self.selector.carouselThumbsWrapper);

      $('body').find('.js-img-zoom-modal').length === 0
        ? $('body').append('<div class="js-img-zoom-modal hidden"></div>')
        : null;
      $imageModal = $('.js-img-zoom-modal');
      if (
        !$imageModal.attr('data-sku-base-id') ||
        $container.attr('data-sku-base-id') !== $imageModal.attr('data-sku-base-id')
      ) {
        $imageModal.attr('data-sku-base-id', $container.attr('data-sku-base-id'));
        $imageModal.empty();
        $imageModal.append($thumbs.clone(), $carousel.clone());
        $imageModal.append(
          '<a href="#" class="js-close-zoom icon--remove"><svg role="img" class="icon icon--close--white"><use xlink:href="#icon--close--white"></use></svg></a>'
        );
        $imageModal.find(self.selector.carouselThumbsWrapper).removeClass('hidden');
        $imageModal.append('<div class="img-zoom-overlay"></div>');
        $('.js-close-zoom')
          .once()
          .on('click', function () {
            $imageModal.addClass('hidden');
          });
        $carousel
          .find(self.selector.carouselSlide)
          .not(self.selector.altPosterWrapper)
          .once()
          .on('click', function () {
            var $currentSlide = $(this);
            var $zoomCarousel = $imageModal.find(self.selector.carousel);
            var $altVideoPoster = $(self.selector.altPoster, $carousel);
            var posterIndex = $carousel
              .find(self.selector.carouselSlide)
              .index($altVideoPoster.parent());

            $imageModal.removeClass('hidden').addClass('img-Zoom-Modal');
            $imageModal.find('.slick-slider').css('opacity', '1');
            $imageModal
              .find(self.selector.carouselThumbsWrapper)
              .css('height', $zoomCarousel.width());
            self.processThumbs($imageModal, $zoomCarousel, posterIndex);
            self.setActiveThumb($imageModal, $zoomCarousel, $currentSlide.index());
            $zoomCarousel.slickGoTo($currentSlide.index());
            if ($altVideoPoster.length > 0) {
              self.initAltVideo($imageModal);
            }
          });
      }
    },

    setZoom: function ($carousel) {
      var self = this;
      var $zoomImages = $(self.selector.carouselSlide, $carousel).not(
        self.selector.altPosterWrapper
      );

      if (self.isMobile()) {
        $zoomImages.trigger('zoom.destroy');
        $('.js-zoom>i')
          .once()
          .on('click', function () {
            // Make the image full screen add a fixed x
            var zoomImage = $('.slick-active', $carousel).html();
            var $this = $(this);

            if ($this.hasClass('zoomed')) {
              $('body > .zoomed-image').remove();
            } else {
              var $imageModal = $();

              $('body').append('<div class="img-Zoom-Modal"></div>');
              $imageModal = $('.img-Zoom-Modal');
              $imageModal.append(zoomImage);
              $imageModal.append('<a href="#" class="js-close-zoom icon--remove"></a>');
              $('.js-close-zoom')
                .once()
                .on('click', function () {
                  $imageModal.remove();
                });
            }
            $this.toggleClass('zoomed');
          });
      } else {
        $('.site-container').css('overflow-x', 'visible');
        $zoomImages.trigger('zoom.destroy');
        $zoomImages.zoom({
          magnify: 1.5
        });
      }
      $zoomImages.each(function (index) {
        var indexlink = 'prodimg' + index;

        $(this).attr('id', indexlink);
      });
    },

    isVisibleThumb: function (parent, child) {
      if (!parent || !child) {
        return;
      }

      return !(
        child.offsetTop - parent.offsetTop >= parent.offsetHeight ||
        child.offsetTop + child.offsetHeight <= parent.offsetTop + parent.scrollTop
      );
    },

    attached: false
  };
})(jQuery, window.prodcat || {}, window.site || {});
